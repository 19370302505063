<template>
  <div class="home d-flex justify-center">
    <div class="d-flex">
      <v-text-field :placeholder="$t('test_key')" v-model="testId"></v-text-field>
      <v-btn :to="/test/+testId" :disabled="testId.length == 0">{{$t('start')}}</v-btn>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
  data: function () {
    return {
      testId: ''
    }
  }
}
</script>

<style lang="scss">
.home {
  height: 100%;
  align-items: center;
  > div{
    //height: 100%;
    max-width: 400px;
    //margin: 0 auto;
  }
}
</style>
