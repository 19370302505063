export default {
    'select_all_questions': 'Bitte wähle alle Fragen aus',
    'test_key': 'Umfrage ID',
    'next': 'Weiter',
    'answer': 'Deine Antwort',
    'answer_req': 'Deine Antwort',
    'send': 'Absenden',
    'error_loading': 'Diese Research-Konfiguration wurde nicht gefunden',
    'error_save': 'Beim Speichern lief etwas schief',
    'of': 'von',
    'rating': 'Ranking',
    'type5_org_image': 'Option 1',
    'type5_compare_image': 'Option 2',
    'dropdown_option': 'Auswahl',
    'duplicate_dropdown': 'Doppelte Auswahl',
    startpage: {
        welcome: 'Herzlich Willkommen',
        welcome_thank: ' und vielen Dank für deine Teilnahme an dieser Umfrage!',
        subtitle: 'Dein Feedback ist äußerst relevant und hilfreich für die Weiterentwicklung unserer Produkte. Bitte lese die Fragen gründlich und bewerten Sie diese so ehrlich wie möglich.',
        time_msg: 'Die Studie wird nicht länger als {0} Minuten dauern.',
        additional_infos: 'Es handelt sich hierbei um eine Marktforschungsstudie, d.h. wir versuchen nicht, dir etwas zu verkaufen, sondern sind nur an deiner ehrlichen Meinung interessiert. Alle Antworten werden streng vertraulich behandelt und nur in ihrer Gesamtheit ausgewertet, somit sind keine Rückschlüsse auf den Einzelnen möglich.',
        button: 'Umfrage starten'
    },
    end: {
        'thank_you': 'Vielen Dank',
        'participation': 'für deine Teilnahme!',
        'feedback': 'Das Feedback ist sehr wertvoll für uns.',
        'feedback-failed': 'Leider gehörst du bei dieser Umfrage nicht zur gesuchten Zielgruppe.',
        'submit': 'Umfrage abschließen'
    },
    duplicate_access_msg: "Vielen Dank. Sie haben bereits an dieser Umfrage teilgenommen."
}
