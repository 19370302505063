export default {
    'select_all_questions': 'Please fill out each question',
    'test_key': 'Suvey key',
    'next':'Next',
    'answer': 'Your answer',
    'answer_req': 'Your answer',
    'send': 'Send',
    'error_loading':'This survey key was not found!',
    'error_save':'Something went wrong, we could not store the results',
    'of':'of',
    'rating':'Rating',
    'type5_org_image':'Option 1',
    'type5_compare_image':'Option 2',
    'dropdown_option': 'Option',
    'duplicate_dropdown': 'Double selection',
    startpage: {
        welcome:'Welcome',
        welcome_thank: ' and thank you for your participation on this survey!',
        subtitle: 'Your feedback is extremely relevant and helpful for the further development of our products. Please read the questions thoroughly and evaluate them as honestly as possible.',
        time_msg: 'The survey will last no more than {0} minutes.',
        additional_infos: 'This is a market research study, i.e. we do not try to sell you something, but are only interested in your honest opinion. All answers are treated as strictly confidential and only evaluated in their entirety, so no conclusions can be drawn about the individual.',
        button: 'Starten survey'
    },
    end: {
        'thank_you': 'Thank you',
        'participation': 'for your participation!',
        'feedback': 'Your feedback is highly appreciated.',
        'feedback-failed': 'Unfortunately, you are not in the target group we are trying to reach in this survey.',
        'submit': 'Click here to complete survey'
    },
    duplicate_access_msg: "Thank you very much. You have already participated in this survey."
}
