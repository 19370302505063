import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import en from './locale/en.ts'
import de from './locale/de.ts'
import de_du from './locale/de_du.ts'
import VueI18n from 'vue-i18n'
import { ValidationProvider , ValidationObserver} from 'vee-validate';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueI18n)
Vue.use(VuePlyr, {
  plyr: {}
})
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: navigator.language.startsWith(('de')) ? 'de' : 'en', // set locale
  messages :{de,en,de_du}, // set locale messages
})

new Vue({
  router,
  vuetify,
  render: h => h(App),
  i18n
}).$mount('#app')
