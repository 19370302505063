<template>
  <v-app :class="style">
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

<!--    <v-footer app>-->
<!--      &lt;!&ndash;      <v-img src="logo-gestalten.svg"&ndash;&gt;-->
<!--      &lt;!&ndash;             max-width="150"></v-img>&ndash;&gt;-->
<!--      &lt;!&ndash;      <v-spacer></v-spacer>&ndash;&gt;-->
<!--      &lt;!&ndash;      <a href="https://www.die-gestalten.net" target="_blank">{{ copytext }}</a>&ndash;&gt;-->
<!--    </v-footer>-->
  </v-app>
</template>
<script>

export default {
  name: 'App',

  components: {},
  data: () => ({
    copytext: 'Copyright © ' + new Date().getFullYear() + ' - Service offered by Die Gestalten GmbH',
    style:''
  }),
  mounted() {
    document.addEventListener('style-choosen',(s)=>this.style = s.detail)
  }
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .container {
    height: 100%;
  }

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
  }

  .v-main__wrap > .container {
    padding: 0;
  }
}


</style>
