export default {
    'select_all_questions': 'Bitte wählen sie alle Fragen aus',
    'test_key': 'Umfrage ID',
    'next': 'Weiter',
    'answer': 'Bitte tragen Sie hier Ihre Antwort ein',
    'answer_req': 'Bitte tragen Sie hier Ihre Antwort ein.',
    'send': 'Absenden',
    'error_loading': 'Diese Research-Konfiguration wurde nicht gefunden',
    'error_save': 'Beim Speichern lief etwas schief',
    'of': 'von',
    'rating': 'Ranking',
    'type5_org_image': 'Option 1',
    'type5_compare_image': 'Option 2',
    'dropdown_option': 'Auswahl',
    'duplicate_dropdown': 'Doppelte Auswahl',
    startpage: {
        welcome: 'Herzlich Willkommen',
        welcome_thank: ' und vielen Dank für Ihre Teilnahme an dieser Umfrage!',
        subtitle: 'Ihr Feedback ist äußerst relevant und hilfreich für die Weiterentwicklung unserer Produkte. Bitte lesen Sie die Fragen gründlich und bewerten Sie diese so ehrlich wie möglich.',
        time_msg: 'Die Studie wird nicht länger als {0} Minuten dauern.',
        additional_infos: 'Es handelt sich hierbei um eine Marktforschungsstudie, d.h. wir versuchen nicht, Ihnen etwas zu verkaufen, sondern sind nur an Ihrer ehrlichen Meinung interessiert. Alle Antworten werden streng vertraulich behandelt und nur in ihrer Gesamtheit ausgewertet, somit sind keine Rückschlüsse auf den Einzelnen möglich.',
        button: 'Umfrage starten'
    },
    end: {
        'thank_you': 'Vielen Dank',
        'participation': 'für die Teilnahme!',
        'feedback': 'Das Feedback ist sehr wertvoll für uns.',
        'feedback-failed': 'Leider gehören Sie bei dieser Umfrage nicht zur gesuchten Zielgruppe.',
        'submit': 'Umfrage abschließen'
    },
    duplicate_access_msg: "Vielen Dank. Sie haben bereits an dieser Umfrage teilgenommen."
}
